<template>
  <component :is="Default">
    <template slot="buttons">
      <div class="buttons">
        <button class="loose transparent thin" @click="logout">log out</button>
      </div>
    </template>
    <div v-if="hasRoles">
      <el-row v-for="role in availableRoles" :key="role" class="role">
        <el-button @click="() => roleClick(role)" class="switch-role is-medium">
          {{ roleText(role) }}
        </el-button>
      </el-row>
      <button v-if="roleRoute" @click="() => $router.push(`/${roleRoute}`)">
        {{ roleRoute }}
      </button>
      <el-dialog
        width="40%"
        top="10vh"
        title="Switch Role"
        class="role-switch-dialog"
        :visible.sync="displayUserRoles"
      >
        <h3 slot="title">Switch Role</h3>
        <role-switcher @closeModal="modalClose" :showType="showType" />
      </el-dialog>
    </div>
    <div v-if="availableRoles.length < 1">
      <p>
        No available roles to access Sideline. Contact your administrator if you
        believe this is a mistake.
      </p>
    </div>
  </component>
</template>

<script>
import RoleSwitcher from "../components/RoleSwitcher.vue";
import Default from "@/layouts/default";
export default {
  name: "landing",
  components: {
    RoleSwitcher,
  },
  data() {
    return {
      Default,
      displayUserRoles: false,
      showType: null,
    };
  },
  methods: {
    logout() {
      this.$store.commit("user/logout");
      this.$router.push("/");
    },
    modalClose() {
      this.displayUserRoles = false;
      this.showType = null;
    },
    roleClick(type) {
      this.displayUserRoles = true;
      this.showType = type;
    },
    roleText(type) {
      switch (type) {
        case 101:
          return "I want to submit a teamlist";
        case 51:
          return "I want to manage a match";
        default:
          return null;
      }
    },
  },
  computed: {
    availableRoles() {
      const roles = this.$store.getters["user/roles"];
      const refine = roles.filter((r) => r.type === 51 || r.type === 101);
      const unique = [...new Set(refine.map((role) => role.type))];
      return unique;
    },
    hasRoles() {
      return this.availableRoles.length && this.availableRoles.length >= 1;
    },
    roleRoute() {
      const role = this.$store.getters["user/activeRole"];
      if (role.type === 101) return "teamlist";
      if (role.type === 51) return "matches";
      return false;
    },
  },
};
</script>

<style></style>
