<template>
  <div class="roles-holder">
    <el-row
      v-for="role in formattedRole"
      :key="role.id"
      class="role"
      :gutter="20"
    >
      <div style="width: 100%; height: 100%" @click="switchRole($event, role)">
        <el-col :span="6" style="height: 100%">
          <div class="img-container">
            <img
              v-if="role.logo"
              :src="role.logo"
              @error="displayPlaceholder"
              class="role-logo"
            />
            <img
              v-else
              src="~@/assets/img/logo/shield_black.svg"
              alt=""
              class="role-logo"
            />
          </div>
        </el-col>
        <el-col :span="14" style="height: 100%">
          <h4 class="role-name">{{ role.name }}</h4>
          <h5 class="role-entity">{{ role.entityName }}</h5>
        </el-col>
        <el-col v-if="isCurrentRole(role._id)" :span="4">
          <i circle class="active-role el-icon-check"></i>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import { orgtree } from "../utils/constants";
import store from "../store";
import moment from "moment";

export default {
  name: "RoleSwitcher",
  data() {
    return {
      store,
    };
  },
  props: {
    showType: Number,
  },
  computed: {
    formattedRole() {
      return (
        this.$store.getters["user/roles"]
          .filter(
            (role) =>
              (role.type === 51 || role.type === 101) &&
              this.showType === role.type,
          )
          .map((role) => ({
            ...role,
            name:
              role.type !== 99 || (role.type > 100 && role.type < 103)
                ? orgtree[role.type].name
                : role.type !== 99
                ? "Program Admin"
                : "Elite Team Admin",
            entityName: role.entity ? role.entity.name : "",
            logo: role.entity ? this.getS3URL(role.entity._id) : null,
          }))
          // eslint-disable-next-line no-nested-ternary
          .sort((a, b) => (a.type > b.type ? 1 : a.type < b.type ? -1 : 0))
      );
    },
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require("../assets/img/logo/shield_black.svg");
    },
    getS3URL(id) {
      `${
        process.env.VUE_APP_S3
      }/logos/resize/${id}.png?v=${moment().valueOf()}`;
    },
    switchRole(event, role) {
      this.store.commit("user/switchRole", role);
      // reset states
      this.$router.go();
      this.$emit("closeModal");
    },
    isCurrentRole(id) {
      return id === this.$store.getters["user/activeRole"]._id;
    },
  },
};
</script>

<style scoped lang="scss">
.img-container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roles-holder {
  height: 26rem;
  overflow: scroll;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.role {
  text-align: left;
  color: black;
  width: 95%;
  margin: 1rem auto !important;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
  overflow: hidden;

  .role-name {
    font-weight: 700;
    padding-top: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold !important;
  }

  .role-entity {
    margin-top: 0;
  }

  .img-container {
    overflow: hidden;
    .role-logo {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      height: 4.5rem;
    }
  }

  .active-role {
    padding: 15px;
    border-radius: 50%;
    font-size: 0.75rem;
    position: absolute;
    right: 6%;
    top: 30%;
    background: $secondary;
    color: white;
  }
}
</style>
